import { NavBar } from '../components/NavBar';
import '../styles/detail.scss';
import { Form } from '../components/contact/Form';
import { Footer } from '../components/Footer';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useDocuments, useProducts } from '@wbsuite/react-hooks';
import noFoto from '../assets/noFoto.png';
// @ts-ignore
import { HashLink as Link } from 'react-router-hash-link';

export const Detail = () => {
	window.scrollTo(0, 0);

	const { productId } = useParams<{ productId: any }>()
	const { list: get } = useProducts();
	const [productDetail, setProductDetail] = useState([]);
	const { getPublicUrl } = useDocuments() as any;

	useEffect(() => {
		get(productId).then(filteredProduct => {
			setProductDetail(filteredProduct.data)
		});
	}, [productId])

	const product = productDetail.find((product: any) => { return product._id === productId }) as any;
	console.log("🚀 ~ product:", product)

	const fichaTecnicaDocument = product?.gallery[1]?.document;
	const fichaTecnicaUrl = fichaTecnicaDocument ? getPublicUrl(fichaTecnicaDocument) : '';
	const nameFichaTecnica = fichaTecnicaDocument?.file?.originalname || 'ficha_tecnica.pdf';

	const handleDownload = async (url: string, filename: string) => {
		const response = await fetch(url);
		const blob = await response.blob();
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	
	return <div>
		<NavBar />
		<div className='detail-page'>
			<div className='product-info'>
				<div className='image'>
					<img src={product?.gallery[0]?.document ? getPublicUrl(product.gallery[0].document) : noFoto} alt="" />
				</div>
				<div className='info'>
					<h2>{product?.name?.es}</h2>
					<h3><b>Categoría:</b><>{product?.categories[0]?.name?.es}</></h3>
					<p className='description' dangerouslySetInnerHTML={{ __html: product?.description?.es }}></p>
					<div className='detail-btn-container'>
						<Link to={`/detalle/${product?._id}/#p-form`} smooth className='detail-button general-button'>Realizar Consulta</Link>
						<button
								className="detail-button general-button download-btn"
								onClick={() => handleDownload(fichaTecnicaUrl, nameFichaTecnica)}
							>
								Descargar Ficha Técnica
							</button>
					</div>
				</div>
			</div>
			<div className='product-contact'>
				<div className='measures'>
					<h2>Medidas</h2>
					<div className='measure-box'>
						{product?.attributes?.map((p: any) => {
							  return (
								<div className='m-data' key={p._id}>
									<p><b>{p.name?.es}</b></p>
									<p>{p.value.es}</p>
								</div>
							);
						})}
					</div>
				</div>
				<div className='product-form'>
					<div id='p-form'></div>
					<h2>Realizá tu consulta</h2>
					<Form />
				</div>
			</div>
		</div>
		<div className='banner'>
			<span>Exelencia en Soluciones Plásticas</span>
			<h2>Descubre nuestra amplia gama de productos sustentables</h2>
			<div className='banner-link'>
				<Link to='/categorias' className='b-button general-button'>Ver Todas</Link>
			</div>
		</div>
		<Footer />
	</div>
}