import { Footer } from '../components/Footer';
import { GoBack } from '../components/GoBack';
import { NavBar } from '../components/NavBar';
import { ProductCard } from '../components/products/ProductCard';
import '../styles/products.scss';
import { useParams } from 'react-router';
import { useDocuments, useProducts } from '@wbsuite/react-hooks';
import { useEffect, useState } from 'react';
import noFoto from '../assets/noFoto.png';
import { ClipLoader } from "react-spinners";

export const Products = () => {
  window.scrollTo(0, 0);
  const {category } = useParams<{category: string}>()
  const { list: listProducts } = useProducts();
  const [ productList, setProductList ] = useState([]);
  const {  getPublicUrl } = useDocuments() as any ;
  

  useEffect(() => {
    listProducts({categories : [category] }).then(filteredProductList => {
     setProductList(filteredProductList.data);
    });
   
  }, [category])

  const products = productList.find((product: any) => {return product.categories._id === category }) as any;

  
  return <div>
      <NavBar />
      <div className='grid-cards'>
      <div className='section-page left-align'>
      <GoBack />
        <h3>{products?.categories?.name?.es}</h3>
      </div>
      <div className='enpa-grid grid-responsive'>
       
        {productList.length !== 0 ? productList.map((p: any, index: number) =>  <ProductCard 
          key={index}
          productName={p.name?.es}  
          productcImg={p.gallery[0]?.document ? getPublicUrl(p.gallery[0]?.document) : noFoto} 
          productId={p._id}/>) : <div className='loader'>
          <ClipLoader color={"#000"} loading={productList.length > 0} size={50} />
        </div>}
      </div>
  </div>
  <Footer />
</div>   
}