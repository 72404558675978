import { Footer } from '../components/Footer';
import { NavBar } from '../components/NavBar';
import '../styles/products.scss';
import { CategoryCard } from '../components/CategoryCard';
import { useCategories, useDocuments } from '@wbsuite/react-hooks';
import { useEffect, useState } from 'react';
import noFoto from '../assets/noFoto.png';
import { ClipLoader } from "react-spinners";


export const Categories = () => {
  window.scrollTo(0, 0);
  const { list: listCategories } = useCategories();
  const [categoriesList, setCategoriesList] = useState<any>([])
  const { getPublicUrl } = useDocuments() as any;

  useEffect(() => {
    listCategories()
      .then((l) => {
        setCategoriesList(l.data)
      })

  }, [])

  const completeList = categoriesList.map((p: any) => {
    return <CategoryCard productName={p.name.es} productcImg={p.document ? getPublicUrl(p.document) : noFoto} categoryId={p._id} />
  })


  return <div>
    <NavBar />
    <div className='grid-cards'>
      <div className='section-page'>
        <h3>Soluciones a medida para cada necesidad</h3>
        <p>Explora nuestra amplia categorías de productos. </p>
      </div>
      <div className='enpa-grid grid-responsive'>
        {categoriesList.length !== 0 ?
          completeList :
          <div className='loader'>
            <ClipLoader color={"#000"} loading={categoriesList.length > 0} size={50} />
          </div>
        }
      </div>
    </div>
    <Footer />
  </div>
}